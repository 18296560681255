import React from "react";
import logo from "./assets/logo.svg";

const Home = () => {
  return (
    <div className="App-empty-wrap">
      <div className="App-logo-wrap">
        <img src={logo} className="App-logo" alt="logo" />

        <div className="App-link-wrap">
          <a
            className="App-link App-apple-link"
            href="https://apps.apple.com/ru/app/goodlike/id1664374466?l=en"
            target="_blank"
          >
            <img className="App-apple-logo" src="assets/images/apple.png" />
          </a>
          <a
            className="App-link"
            href="https://play.google.com/store/apps/details?id=com.goodlike"
            target="_blank"
          >
            <img className="App-apple-logo" src="assets/images/google.png" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Home;
