import React from "react";
import { Routes, Route } from "react-router";

import Layout from "./routes/Layout";
import Home from "./routes/Home";
import Privacy from "./routes/Privacy";

import "./App.css";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="privacy" element={<Privacy />} />

        <Route path="*" element={<Home />} />
      </Route>
    </Routes>
  );
}

export default App;
